// zn.js
export default {
  params: "参数",
  device: "设备",
  handle: "操作",
  inRoom: "进入房间",
  publish: "发布流",
  stopPublish: "取消发布流",
  leave: "离开房间",
  shareScreen: "共享屏幕",
  stopShareScreen: "停止共享屏幕",
  log:"日志",
  links:"链接",
};
