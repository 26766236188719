/*
 * @Description: 通用函数
 */

/**
 * 从 window.location.href 中获取指定key的value
 * @param {*} key 要获取的 key
 * @returns window.location.href 中指定key对应的value
 * @example
 * const value = getUrlParam(key);
 */
export function getUrlParam(key) {
  const url = decodeURI(window.location.href.replace(/^[^?]*\?/, ""));
  const regexp = new RegExp(`(^|&)${key}=([^&#]*)(&|$|)`, "i");
  const paramMatch = url.match(regexp);

  return paramMatch ? paramMatch[2] : null;
}

export function clearUrlParam() {
  location.href = location.href.slice(
    0,
    location.href.indexOf("?") > 0
      ? location.href.indexOf("?")
      : location.href.length
  );
}

export function isUndefined(value) {
  return value === "undefined";
}

export const randomString = (e) => {
  e = e || 32;
  let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  let a = t.length;
  let n = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
};

/**
 * 当前浏览器是否为移动端浏览器
 */
export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

/**
 * 随机字符串
 * @param {*} hashLength
 * @returns
 */

export function createHash(hashLength) {
  return Array.from(Array(Number(hashLength) || 24), () =>
    Math.floor(Math.random() * 36).toString(36)
  ).join("");
}

/**
 * 函数防抖
 * @param func 函数
 * @param delay 延迟执行毫秒数
 * @param immediate true=立即执行(执行第一次)，false=非立即执行(执行最后一次)
 */
export const debounce = function (fn, delay, immediate) {
  let timer = null;
  return function () {
    let that = this;
    let arg = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    if (immediate) {
      let bool = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, delay);
      if (bool) {
        fn.apply(that, arg);
      }
    } else {
      timer = setTimeout(() => {
        timer = null;
        fn.apply(that, arg);
      }, delay);
    }
  };
};
