import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./locales";
import TRTC from "trtc-js-sdk";
import "@/assets/style/global.scss";
import "@/assets/icons";
import { isMobile } from "@/utils/utils";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

import websocket from "vue-native-websocket";

Vue.use(websocket, "wss://im.ws.wltwo107.qrqy.net/ws/server", {
  format: "json", // json格式
  reconnection: true, // (Boolean)是否自动重连，默认false
  reconnectionAttempts: 5, // 重连次数
  reconnectionDelay: 2000, // 再次重连等待时常(1000)
});

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(duration);
Vue.prototype.dayjs = dayjs; //可以全局使用dayjs


//sdkAppId
Vue.prototype.sdkAppId = 1600013962;
Vue.prototype.secretKey =
  "713d47190a3e09d6ea5b964b366efba71efbbc58b7b7183344ca3f5bdd3d2fce";

Vue.prototype.$isMobile = isMobile;

Vue.config.productionTip = false;
Vue.use(Antd);

TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.DEBUG);
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
