// en.js
export default {
  params: "Params",
  device: "Device",
  handle: "Handle",
  inRoom: "Join room",
  publish: "Publish stream",
  stopPublish: "Stop publish stream",
  leave: "Leave room",
  shareScreen: "Share the screen",
  stopShareScreen: "Stop share the screen",
  log: "log",
  links: "links",
};
