<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  mounted() {
    // this.$aegis.reportEvent({
    //   name: "loaded",
    //   ext1: "loaded-success",
    //   ext2: "webrtcQuickDemoVue2",
    // });
  },
};
</script>
<style lang="scss">
#app {
  max-width: 1366px;
  margin: 0 auto;
}
</style>
