import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/login"),
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import("../views/login/forget"),
  },
  {
    path: "/participantLogin",
    name: "participantLogin",
    component: () => import("../views/participantLogin"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/index"),
  },
  {
    path: "/history",
    name: "history",
    component: () => import("../views/home/history"),
  },
  {
    path: "/historicalSessions",
    name: "historicalSessions",
    component: () => import("../views/historicalSessions/index"),
  },
  {
    path: "/startSessions",
    name: "startSessions",
    component: () => import("../views/startSessions/index"),
  },
  {
    path: "/sessions",
    name: "sessions",
    component: () => import("../views/sessionsManagement/index"),
  },
  {
    path: "/participant",
    name: "participant",
    component: () => import("../views/participant/index"),
  },
  {
    path: "/participant/sessionExhibit",
    name: "sessionExhibit",
    component: () => import("../views/participant/sessionExhibit/index"),
  },
  {
    path: "/default",
    name: "default",
    component: () => import("../views/participantLogin/default"),
  },
  {
    path: "/sorry",
    name: "sorry",
    component: () => import("../views/participantLogin/sorry"),
  },
  {
    path: "/client",
    name: "client",
    component: () => import("../views/client/index"),
  },
  {
    path: "/clientWaiting",
    name: "clientWaiting",
    component: () => import("../views/client/waiting"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
